function trackFileDownload(event) {
    // Potential race condition of whether this logs in time before page navigates. Docs are unclear on this
    // however, it's likely possible the callback doesn't fire if GA is misconfigured or blocked

    if (typeof gtag === 'function') {
        // event.preventDefault();
        gtag('event', 'custom_download', {
            'custom_download_title': event.target.dataset.gaDownloadTitle,
            'custom_download_label': event.target.dataset.gaDownloadLabel,
            // 'event_callback': () => {
            //     // navigate to page
            // }
        })
    }
}

$(document).ready(() => {
    $('.ga-file-download').on('click', trackFileDownload)
})
